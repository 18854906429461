import React, { useState } from "react";
import "./TwoColumnTable.css";
import Loading from "./LoadingAnimation";
import InfoTooltip from "./InfoTooltip";

const ThreeColumnTable = ({
  data = [],
  isLoaded = true,
  error = null,
  column1Title = "Column 1",
  column2Title = "Column 2",
  column3Title = "Column 3",
  column1Key = "col1",
  column2Key = "col2",
  column3Key = "col3",
  columnRatio = "33-33-34",
  defaultNumberOfRows = 5,
  tooltipText = null,
  sortColumn = null,
  sortDirection = "desc",
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(defaultNumberOfRows);

  // Sort data if sortColumn is specified
  if (sortColumn) {
    data.sort((a, b) => {
      const comparison =
        sortDirection === "desc"
          ? b[sortColumn] - a[sortColumn]
          : a[sortColumn] - b[sortColumn];
      return comparison;
    });
  }

  const totalPages = Math.ceil(data.length / rowsPerPage);

  const currentData = data.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const [col1Width, col2Width, col3Width] = columnRatio.split("-").map(Number);

  const renderEmptyRows = (count) => {
    return Array.from({ length: count }).map((_, i) => (
      <tr key={`empty-${i}`}>
        <td style={{ width: `${col1Width}%` }}>&nbsp;</td>
        <td style={{ width: `${col2Width}%` }}>&nbsp;</td>
        <td style={{ width: `${col3Width}%` }}>&nbsp;</td>
      </tr>
    ));
  };

  return (
    <div className="kv-table-container">
      <table className="kv-table">
        <thead>
          <tr>
            <th style={{ width: `${col1Width}%` }}>
              {column1Title}{" "}
              {tooltipText && <InfoTooltip tooltipText={tooltipText} />}
            </th>
            <th style={{ width: `${col2Width}%` }}>{column2Title}</th>
            <th style={{ width: `${col3Width}%` }}>{column3Title}</th>
          </tr>
        </thead>
        <tbody>
          {data.length === 0 ? (
            <>
              <tr>
                <td colSpan="3">
                  {error ? (
                    "Sorry! An error occurred while fetching the data."
                  ) : isLoaded && data.length === 0 ? (
                    "No data available"
                  ) : (
                    <Loading />
                  )}
                </td>
              </tr>
              {renderEmptyRows(rowsPerPage - 1)}
            </>
          ) : (
            <>
              {currentData.map((row, index) => (
                <tr key={index}>
                  <td style={{ width: `${col1Width}%` }}>{row[column1Key]}</td>
                  <td style={{ width: `${col2Width}%` }}>{row[column2Key]}</td>
                  <td style={{ width: `${col3Width}%` }}>{row[column3Key]}</td>
                </tr>
              ))}
              {renderEmptyRows(rowsPerPage - currentData.length)}
            </>
          )}
        </tbody>
      </table>

      {data.length > 0 && (
        <div className="table-footer">
          <div className="pagination-controls">
            <button
              className="pagination-button"
              onClick={handlePrev}
              disabled={currentPage === 1}
            >
              &lt;
            </button>
            <span className="page-info">
              Page {currentPage} of {totalPages}
            </span>
            <button
              className="pagination-button"
              onClick={handleNext}
              disabled={currentPage === totalPages}
            >
              &gt;
            </button>
          </div>

          {/* rows per page div  */}
          {/* <div>
            <label>Rows per page: &nbsp; &nbsp;</label>
            <select
              value={rowsPerPage}
              onChange={(e) => {
                setRowsPerPage(Number(e.target.value));
                setCurrentPage(1);
              }}
            >
              <option value={5}>5</option>
              <option value={10}>10</option>
            </select>
          </div> */}
        </div>
      )}
    </div>
  );
};

export default ThreeColumnTable;
