import React, { useState } from "react";
import "./SearchBar.css";

const SearchBar = ({
  startDate,
  endDate,
  setStartDate,
  setEndDate,
  allApplications,
  selectedApplicationIds,
  setSelectedApplicationIds,
}) => {
  const [tempSelected, setTempSelected] = useState([...selectedApplicationIds]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isSelectAll, setIsSelectAll] = useState(false);

  const handleStartDateChange = (e) => {
    setStartDate(new Date(e.target.value));
  };

  const handleEndDateChange = (e) => {
    setEndDate(new Date(e.target.value));
  };

  const handleCheckboxChange = (appId) => {
    setTempSelected((prev) => {
      if (prev.includes(appId)) {
        return prev.filter((id) => id !== appId);
      }
      return [...prev, appId];
    });
  };

  const handleApply = () => {
    setSelectedApplicationIds(tempSelected);
    setIsDropdownOpen(false);
  };

  const handleSelectAll = () => {
    if (isSelectAll) {
      setTempSelected([]); // Unselect all
    } else {
      setTempSelected([...allApplications]); // Select all
    }
    setIsSelectAll(!isSelectAll);
  };

  return (
    <div className="search-bar-container d-flex justify-content-between align-items-center">
      {/* Date Range Selector */}
      <div className="date-range-selector d-flex align-items-center">
        <span className="me-2" style={{ color: "#0A4650" }}>
          Select period:
        </span>
        <input
          type="date"
          className="form-control me-2"
          value={startDate.toISOString().split("T")[0]}
          onChange={handleStartDateChange}
        />
        <span className="me-2" style={{ color: "#0A4650" }}>
          to
        </span>
        <input
          type="date"
          className="form-control"
          value={endDate.toISOString().split("T")[0]}
          onChange={handleEndDateChange}
        />
        {/* Applications Dropdown */}
        <div className="dropdown ms-2">
          <button
            className="btn btn-outline-secondary dropdown-toggle"
            type="button"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            Applications ({selectedApplicationIds.length})
          </button>
          <div
            className={`dropdown-menu p-2 ${isDropdownOpen ? "show" : ""}`}
            style={{ minWidth: "350px" }}
          >
            <div className="d-flex justify-content-between align-items-center">
              <h6 className="dropdown-header">Select Applications</h6>
              <button
                className="btn btn-sm btn-outline-primary"
                onClick={handleSelectAll}
              >
                {isSelectAll ? "Deselect All" : "Select All"}
              </button>
            </div>
            <div className="dropdown-divider"></div>
            {allApplications.map((appId) => (
              <div key={appId} className="form-check mb-2">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id={appId}
                  checked={tempSelected.includes(appId)}
                  onChange={() => handleCheckboxChange(appId)}
                />
                <label className="form-check-label" htmlFor={appId}>
                  {appId.replace(/-/g, " ")}
                </label>
              </div>
            ))}
            <div className="dropdown-divider"></div>
            <button className="btn btn-primary w-100" onClick={handleApply}>
              Apply
            </button>
          </div>
        </div>
      </div>

      {/* <div
        className="search-and-filter d-flex align-items-center"
        style={{ paddingRight: "1rem" }}
      > */}
      {/* Search Bar */}
      {/* <div className="input-group me-2">
          <input
            type="text"
            className="form-control search-input"
            placeholder="Search..."
            aria-label="Search"
          />
          <span className="input-group-text search-icon-container">
            <i className="bi bi-search search-icon"></i>
          </span>
        </div> */}
      {/* </div> */}
    </div>
  );
};

export default SearchBar;
