import { useState, useEffect, useRef } from "react";

const usePostData = (url, initialRequestBody, options = {}) => {
  const [responseData, setResponseData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState(null);
  const requestBodyRef = useRef(initialRequestBody);

  const postData = async (newRequestBody = null) => {
    if (newRequestBody) {
      requestBodyRef.current = newRequestBody;
    }

    if (!requestBodyRef.current) return;

    setIsLoaded(false);
    setError(null);
    setResponseData([]);

    if (options.skip) {
      return;
    }

    console.log("Posting data to:", url);
    console.log("Request Body:", requestBodyRef.current);

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          ...options.headers,
        },
        body: JSON.stringify(requestBodyRef.current),
      });

      const response_json = await response.json();
      const data = response_json["data"];
      const response_time_ms = response_json["response_time_ms"];
      const bigquery_bytes_billed = response_json["bigquery_cost_mb"];

      console.log(
        "Successfully posted to: ",
        url,
        "\nResponse Time (ms): ",
        response_time_ms,
        "\nBigQuery bytes billed: ",
        bigquery_bytes_billed
      );

      setResponseData(data);
      setIsLoaded(true);
    } catch (error) {
      console.error("Error posting data:", error);
      setError(error);
    }
  };

  useEffect(() => {
    requestBodyRef.current = initialRequestBody;
    postData(initialRequestBody);
    // eslint-disable-next-line
  }, [url, JSON.stringify(initialRequestBody), options.skip]);

  return [responseData, isLoaded, error, postData];
};

export default usePostData;
