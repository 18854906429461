import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import Loading from "./LoadingAnimation";
import InfoTooltip from "./InfoTooltip";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = ({
  data = [],
  isLoaded = true,
  error = null,
  title,
  labelKey = "key",
  valueKey = "value",
  minHeight = "250px",
  tooltipKey = "Count",
  tooltipText = null,
}) => {
  const sortedData = data.sort((a, b) => b[valueKey] - a[valueKey]);

  const chartData = {
    labels: sortedData.map((item) => item[labelKey]),
    datasets: [
      {
        data: sortedData.map((item) => parseInt(item[valueKey])),
        backgroundColor: [
          "#2b86b3",
          "#3db0cb",
          "#64d9dd",
          "#2e366c",
          "#2c5d93",
          "#FFCE56",
          "#4BC0C0",
          "#FF9F40",
          "#FF6384",
        ],
        borderRadius: 4,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            return `${tooltipKey}: ${context.formattedValue}`;
          },
        },
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 10,
          },
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          color: "#f0f0f0",
        },
        ticks: {
          font: {
            size: 10,
          },
        },
      },
    },
  };

  return (
    <div
      style={{
        backgroundColor: "#F5F5F5",
        padding: "10px",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        minHeight: "200px",
      }}
    >
      <p
        style={{
          margin: "0 0 10px 0",
          fontWeight: "bold",
          fontSize: "16px",
          textAlign: "left",
        }}
      >
        {title} {tooltipText && <InfoTooltip tooltipText={tooltipText} />}
      </p>
      {error ? (
        <p>Sorry! An error occurred while fetching the data.</p>
      ) : isLoaded && data.length === 0 ? (
        <p>No data available</p>
      ) : isLoaded ? (
        <div
          className="d-flex align-items-center"
          style={{ flex: 1, minHeight: minHeight }}
        >
          <Bar data={chartData} options={options} />
        </div>
      ) : (
        <Loading />
      )}
    </div>
  );
};

export default BarChart;
