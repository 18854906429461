import "./Analytics.css";
import React, { useState, useEffect } from "react";
import SideBar from "./SideBar";
import TopNavBar from "./TopNavBar";
import SearchBar from "./SearchBar";
import usePostData from "./usePostData";
import useLoadData from "./useLoadData";
import DonutChart from "./DonutChart";
import TimeseriesChart from "./TimeseriesChart";
import BarChart from "./BarChart";
import QueryFilterTabs from "./QueryFilterTabs";
import ThreeColumnTable from "./ThreeColumnTable";
import { useParams } from "react-router-dom";
// import InfoTooltip from "./InfoTooltip";

const Analytics = () => {
  const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;
  console.log("Backend URL: ", BACKEND_URL);
  const [startDate, setStartDate] = useState(new Date("2024-10-01"));
  const [endDate, setEndDate] = useState(new Date());
  const { client_id } = useParams();

  // First fetch applications
  const [applications, applicationsLoaded, applicationsError] = useLoadData(
    `${BACKEND_URL}/api/v1/analytics/AllApplications/${client_id}`
  );

  const [allApplications, setAllApplications] = useState([]);
  const [selectedApplicationIds, setSelectedApplicationIds] = useState([]);

  // Update applications state when data is loaded
  useEffect(() => {
    if (applicationsLoaded && applications) {
      setAllApplications(applications);
      setSelectedApplicationIds(applications); // Initially select all applications
    }
  }, [applications, applicationsLoaded]);

  // analytics data
  const [
    ApplicationTraffic,
    applicationTrafficLoaded,
    applicationTrafficError,
  ] = usePostData(
    `${BACKEND_URL}/api/v1/analytics/ApplicationsCount`,
    {
      client_id: client_id,
      start_date: startDate.toISOString().split("T")[0],
      end_date: endDate.toISOString().split("T")[0],
      application_ids: selectedApplicationIds,
    },
    {
      skip: !applicationsLoaded || selectedApplicationIds.length === 0,
    }
  );

  const applicationRelevance = ApplicationTraffic.map((item) => ({
    application_id: item.application_id,
    avg_relevance: item.avg_relevance * 100,
  }));

  console.log("Application Traffic: ", ApplicationTraffic);
  console.log("Application Relevance: ", applicationRelevance);

  const [TimeSeriesData, timeSeriesLoaded, timeSeriesError] = usePostData(
    `${BACKEND_URL}/api/v1/analytics/FrequencyAndScoreTimeseries`,
    {
      client_id: client_id,
      start_date: startDate.toISOString().split("T")[0],
      end_date: endDate.toISOString().split("T")[0],
      application_ids: selectedApplicationIds,
    },
    {
      skip: !applicationsLoaded || selectedApplicationIds.length === 0,
    }
  );

  const scaledTimeSeries = TimeSeriesData.map((item) => ({
    query_date: item.query_date,
    avg_relevance: item.avg_relevance * 100,
  }));

  const [ClusteredQueriesData, clusteredQueriesLoaded, clusteredQueriesError] =
    usePostData(
      `${BACKEND_URL}/api/v1/analytics/ClusteredQueries`,
      {
        client_id: client_id,
        start_date: startDate.toISOString().split("T")[0],
        end_date: endDate.toISOString().split("T")[0],
        application_ids: selectedApplicationIds,
      },
      {
        skip: !applicationsLoaded || selectedApplicationIds.length === 0,
      }
    );

  const clusterValueCounts = ClusteredQueriesData?.cluster_value_counts || [];

  const clusterQueries = ClusteredQueriesData?.clustered_queries || [];

  const poorlyPerformedQueries = clusterQueries.filter(
    (q) => q?.relevance_score < 0.15
  );

  const somewhatPerformedQueries = clusterQueries.filter(
    (q) => q.relevance_score >= 0.15 && q.relevance_score < 3
  );

  const suggested_queries = ClusteredQueriesData["suggested_queries"];

  // const [TopPerformingURIs, topPerformingURIsLoaded, topPerformingURIsError] =
  //   usePostData(
  //     `${BACKEND_URL}/api/v1/analytics/TopPerformingURIs`,
  //     {
  //       client_id: client_id,
  //       start_date: startDate.toISOString().split("T")[0],
  //       end_date: endDate.toISOString().split("T")[0],
  //       application_ids: selectedApplicationIds,
  //     },
  //     {
  //       skip: !applicationsLoaded || selectedApplicationIds.length === 0,
  //     }
  //   );

  // const TopPerformingPages = TopPerformingURIs.map((item) => ({
  //   ...item,
  //   uri: (
  //     <a
  //       href={item.uri}
  //       target="_blank"
  //       rel="noopener noreferrer"
  //       style={{ color: "black", textDecoration: "underline" }}
  //     >
  //       {item.title}
  //     </a>
  //   ),
  //   traffic: item.count,
  // }));

  useEffect(() => {
    console.log("UseEffect Is Called");
  }, [startDate, endDate, selectedApplicationIds]);

  if (applicationsError) {
    return (
      <div>
        <SideBar current_page={"analytics"} />
        <div className="main-page">
          <TopNavBar />
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 text-center">
                <h2 className="mt-4">Elelem RAG Query Analytics</h2>
                <div className="alert alert-danger" role="alert">
                  Error loading clients.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (applicationsLoaded && selectedApplicationIds.length === 0) {
    return (
      <div>
        <SideBar current_page={"analytics"} />
        <div className="main-page">
          <TopNavBar />
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8 text-center">
                <h2 className="mt-4">Elelem RAG Query Analytics</h2>
                <div className="alert alert-info" role="alert">
                  No Data Available.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <SideBar current_page={"analytics"} />
      <div className="main-page">
        <TopNavBar />
        <SearchBar
          startDate={startDate}
          endDate={endDate}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          allApplications={allApplications}
          selectedApplicationIds={selectedApplicationIds}
          setSelectedApplicationIds={setSelectedApplicationIds}
        />
        <div className="dashboard">
          <h1 style={{ "text-align": "center" }}>
            {" "}
            Elelem RAG Query Analytics{" "}
          </h1>

          <h2>LLM Application Usage Patterns </h2>

          <div className="row row-eq-height">
            <div className="col-md-5">
              <div className="chart_container">
                <DonutChart
                  title="Application Traffic"
                  data={ApplicationTraffic}
                  isLoaded={applicationTrafficLoaded}
                  error={applicationTrafficError}
                  labelKey="application_id"
                  valueKey="count"
                  tooltipText="Agents and applications accessing your knowledge base(s)"
                />
              </div>
            </div>

            <div className="col-md-7">
              <div className="chart_container">
                <TimeseriesChart
                  data={TimeSeriesData}
                  isLoaded={timeSeriesLoaded}
                  error={timeSeriesError}
                  title="Frequency Over Time"
                  labelKey="query_date"
                  valueKey="frequency"
                  startDate={startDate}
                  endDate={endDate}
                  tooltipKey="Frequency"
                  tooltipText="The number of queries recorded per day during the selected time interval"
                />
              </div>
            </div>
          </div>

          <br />
          <div className="row row-eq-height">
            <br />

            <div className="col-md-6">
              <div className="chart_container">
                <TimeseriesChart
                  data={scaledTimeSeries}
                  isLoaded={timeSeriesLoaded}
                  error={timeSeriesError}
                  title="Average Relevance Over Time"
                  labelKey="query_date"
                  valueKey="avg_relevance"
                  chartType="bar"
                  startDate={startDate}
                  endDate={endDate}
                  tooltipKey="Relevance"
                  tooltipText="The average relevance score of queries recorded per day during the selected time interval"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="chart_container">
                <BarChart
                  title="Average Relevance Score of Applications"
                  data={applicationRelevance}
                  isLoaded={applicationTrafficLoaded}
                  error={applicationTrafficError}
                  labelKey="application_id"
                  valueKey="avg_relevance"
                  minHeight="300px"
                  tooltipKey="Relevance"
                  tooltipText="Evaluating Application and Agent Performance: Insights and Improvement Opportunities"
                />
              </div>
            </div>
          </div>

          <br />

          <h2>Query Topic Analysis </h2>
          <div className="row row-eq-height">
            <div className="col-md-6">
              <div className="chart_container">
                <BarChart
                  data={clusterValueCounts}
                  isLoaded={clusteredQueriesLoaded}
                  error={clusteredQueriesError}
                  title="Clustered Queries"
                  labelKey="cluster_name"
                  valueKey="count"
                  tooltipKey="Number of Queries"
                  tooltipText="Query Distribution by Top-Level Topics: Visualizing the Number of Queries Grouped by Topic, Filtered by Date Range, Application, and Knowledge Base"
                />
              </div>
            </div>
          </div>
          <br />

          <h2>Knowledge Base Gaps</h2>
          <div className="row row-eq-height">
            <div className="col-md-6">
              <div className="chart_container">
                <ThreeColumnTable
                  data={suggested_queries}
                  isLoaded={clusteredQueriesLoaded}
                  error={clusteredQueriesError}
                  column1Key="query"
                  column1Title="Highest impact for improvement"
                  column2Key="relevance_score"
                  column2Title="Avg Score"
                  column3Key="count"
                  column3Title="Count"
                  tooltipText="Queries that are not present in your content but are frequently asked by users"
                  columnRatio="60-20-20"
                  defaultNumberOfRows={5}
                />
              </div>
            </div>
          </div>

          <br />

          <div className="row row-eq-height">
            <div className="col-md-6">
              <div className="chart_container">
                <QueryFilterTabs
                  data={poorlyPerformedQueries}
                  isLoaded={clusteredQueriesLoaded}
                  error={clusteredQueriesError}
                  columnKey="query"
                  columnTitle="Missing Content"
                  tooltipText="Query Topics not covered by your content - consider adding content on these topics"
                />
              </div>
            </div>

            <div className="col-md-6">
              <div className="chart_container">
                <QueryFilterTabs
                  data={somewhatPerformedQueries}
                  isLoaded={clusteredQueriesLoaded}
                  error={clusteredQueriesError}
                  columnKey={"query"}
                  columnTitle={"Content Improvement"}
                  tooltipText={
                    "Query Topics only partially addressed by your content - consider broadening and/or deepening your content on these topics"
                  }
                  defaultNumberOfRows={5}
                />
              </div>
            </div>
          </div>

          <br />

          {/* temporarily hiding Top Performing Pages as it belongs to a different section  */}
          {/* <div class name="row row-eq-height">
            <div className="col-md-5">
              <div className="chart_container">
                <KeyValueTable
                  data={TopPerformingPages}
                  isLoaded={topPerformingURIsLoaded}
                  error={topPerformingURIsError}
                  column1Key="uri"
                  column1Title="Top Performing Pages / URIs"
                  column2Key="traffic"
                  column2Title="Traffic"
                  tooltipText="Pages that are frequently being used for answering user queries"
                  columnRatio="80-20"
                  defaultNumberOfRows={5}
                />
              </div>
            </div>
          </div> */}
        </div>
        <div></div>
        <footer className="footer bg-light text-center text-lg-start">
          <div className="container p-4">
            <span
              className="text-muted"
              style={{ display: "block", textAlign: "center" }}
            >
              © 2025 Elelem AI. All rights reserved.
            </span>
          </div>
        </footer>
      </div>
    </div>
  );
};

export default Analytics;
