import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const token = sessionStorage.getItem("token");
    const clientId = sessionStorage.getItem("client_id");

    if (token && clientId) {
      navigate(`/analytics/${clientId}`);
    } else {
      navigate("/login");
    }
  }, [navigate]);

  return null;
};

export default Home;
