import SideBar from "./SideBar";
import TopNavBar from "./TopNavBar";

const ControlRoom = () => {
  return (
    <div>
      <SideBar current_page={"control-room"} />
      <div className="main-page">
        <TopNavBar />
        <div className="dashboard" style={{ textAlign: "center" }}>
          <h2>404 Not Found</h2>
          <p>This page you are looking for is not found.</p>
        </div>
        <br />
      </div>
    </div>
  );
};

export default ControlRoom;
