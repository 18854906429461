// src/TopNavBar.js
import "./TopNavBar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const TopNavBar = () => {
  const [showDropdown, setShowDropdown] = useState(false);
  const navigate = useNavigate();
  const token = sessionStorage.getItem("token");
  const firstName = sessionStorage.getItem("firstname") || "";
  const lastName = sessionStorage.getItem("lastname") || "";
  const profilePicture = sessionStorage.getItem("profile_picture") || "";

  const handleLogout = () => {
    sessionStorage.clear();
    navigate("/login");
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          <img
            src="https://mahdee-kamal.sirv.com/logo.png"
            alt="Blog Logo"
            style={{ maxHeight: "60px" }}
          />
        </a>
        {token && (
          <div className="d-flex align-items-center justify-content-end">
            <div
              className="navbar-user position-relative"
              onClick={() => setShowDropdown(!showDropdown)}
              style={{ cursor: "pointer" }}
            >
              <h6>
                Hello {firstName} <b> {lastName} </b>
              </h6>
              <span className="down-arrow">&#9662;</span>

              {showDropdown && (
                <div
                  className="position-absolute bg-white shadow-sm rounded py-2"
                  style={{
                    right: 0,
                    top: "100%",
                    zIndex: 1000,
                    minWidth: "150px",
                  }}
                >
                  <button
                    className="dropdown-item btn btn-secondary text-center"
                    onClick={handleLogout}
                  >
                    Logout
                  </button>
                </div>
              )}
            </div>

            <span>
              <img
                src={profilePicture}
                alt="User Avatar"
                className="navbar-avatar"
              />
            </span>
          </div>
        )}
      </div>
    </nav>
  );
};

export default TopNavBar;
